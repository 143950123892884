<template>
  <v-card class="px-3 text-right" flat v-if="cart.cartId > 0">
    <v-card-text class="default--text text-darken-2">
      <!-- <div class="d-flex justify-end align-top font-weight-bold">
        <span>{{ $t("cartSummary.totalPrice") }}</span>
        <span class="value">
          {{ $n(cart.totalPrice, "currency") }}
        </span>
      </div> -->
      <div class="d-flex justify-end align-top font-weight-bold ">
        <span>{{ $t("cartSummary.totalPriceVariable") }}</span>
        <span class="value">{{ $n(cart.totalPriceVariable, "currency") }}</span>
      </div>
      <div class="d-flex justify-end">
        <v-btn
          plain
          color="primary"
          class="empty-cart-btn col-6 mr-8 d-none d-md-flex"
          :disabled="cart.totalItems == 0"
          @click="emptyCartConfirm"
          >{{ $t("cart.emptyCart") }}</v-btn
        >
        <div
          class="text-h4 d-flex justify-end align-center font-weight-bold text-uppercase black--text"
        >
          <span>{{ $t("cartSummary.grossTotal") }}</span
          ><span class="value">{{ $n(cart.totalPrice, "currency") }}</span>
        </div>
      </div>
    </v-card-text>
    <v-card-actions class="justify-end">
      <v-row no-gutters>
        <v-col :cols="cart.totalItems > 0 ? 6 : 12" class="pa-1">
          <v-btn
            outlined
            color="primary"
            min-width="95%"
            :x-large="!$vuetify.breakpoint.xs"
            v-on:click="closeDrawer"
            depressed
          >
            {{ $t("cart.button.goBack") }}
          </v-btn>
          <!-- <v-btn
            class="bg-white"
            x-large
            outlined
            color="primary"
            depressed
            min-width="95%"
            v-on:click="emptyCart"
            v-if="!full"
            >{{ $t("cart.button.emptyCart") }}</v-btn
          > -->
        </v-col>
        <v-col cols="6" class="pa-1" v-if="cart.totalItems > 0">
          <v-btn
            class="checkout-btn"
            to="/checkout"
            color="primary"
            min-width="95%"
            :x-large="!$vuetify.breakpoint.xs"
            depressed
            >{{ $t("cart.button.goToPayment") }}</v-btn
          >
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
  <v-card v-else>{{ $t("no-cart") }}</v-card>
</template>
<style scoped lang="scss">
.value {
  min-width: 150px;
}
</style>
<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "CartSummary",
  props: ["full"],
  computed: {
    ...mapState({
      cart: ({ cart }) => cart.cart
    })
  },
  methods: {
    ...mapActions({
      emptyCart: "cart/emptyCart",
      updatedDrawerRight: "app/updatedDrawerRight",
      removeGiftCode: "cart/removeGiftCode"
    }),
    async emptyCartConfirm() {
      if (this.cart.totalItems > 0) {
        let res = await global.vm.$dialog.confirm({
          text: global.vm.$t("message.emptyCart")
        });
        if (res) {
          this.emptyCart();
        }
      }
    },
    closeDrawer() {
      this.updatedDrawerRight(false);
    },
    removeCode(giftCodeId) {
      this.removeGiftCode(giftCodeId);
    },
    changeService() {},
    changeTimeslot() {},
    changeDelivery() {}
  }
};
</script>
<style scoped lang="scss">
@media #{map-get($display-breakpoints, 'xs-only')} {
  .v-card__actions {
    padding: 8px 0;
  }
}
</style>
