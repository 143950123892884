import { handleCategoryNavigation } from "./routeFunctions";
import store from "@/store";
let routes = [
  {
    path: "/reset-password-request",
    name: "ResetPasswordRequest",
    component: () => import("@/views/profile/ResetPasswordRequest.vue"),
    beforeEnter(to, from, next) {
      handleCategoryNavigation("reset-password-request", to, from, next);
    }
  },
  {
    path: "/reset-password-sent",
    name: "ResetPasswordSent",
    component: () => import("@/views/EbsnCategory.vue"),
    beforeEnter(to, from, next) {
      handleCategoryNavigation("reset-password-sent", to, from, next);
    }
  },
  // {
  //   path: "/reset-password",
  //   name: "ResetPassword",
  //   component: () => import("@/views/profile/ResetPassword.vue"),
  //   beforeEnter(to, from, next) {
  //     handleCategoryNavigation("reset-password", to, from, next);
  //   }
  // },
  {
    path: "/recover-password-confirm",
    name: "ResetPassword",
    component: () => import("@/views/profile/ResetPassword.vue"),
    beforeEnter(to, from, next) {
      handleCategoryNavigation("reset-password", to, from, next);
    }
  },
  {
    path: "/reset-password-completed",
    name: "ResetPasswordCompleted",
    component: () => import("@/views/EbsnCategory.vue"),
    props: { showLogin: true },
    beforeEnter(to, from, next) {
      handleCategoryNavigation("reset-password-completed", to, from, next);
    }
  },
  {
    path: "/registration-user",
    name: "RegistrationUser",
    component: () => import("@/views/profile/RegistrationUser.vue"),
    beforeEnter(to, from, next) {
      handleCategoryNavigation("registration-user", to, from, next);
    }
  },
  {
    path: "/registration-completed",
    name: "RegistrationCompleted",
    component: () => import("@/views/EbsnCategory.vue"),
    beforeEnter(to, from, next) {
      handleCategoryNavigation("registration-completed", to, from, next);
    }
  },
  {
    path: "/registration-confirm",
    name: "RegistrationConfirmed",
    component: () => import("@/views/profile/RegistrationConfirmed.vue"),
    props: { showLogin: true },
    beforeEnter(to, from, next) {
      handleCategoryNavigation("registration-confirm", to, from, next);
    }
  },
  {
    path: "/email-confirmed",
    name: "EmailConfirmed",
    component: () => import("@/views/profile/RegistrationConfirmed.vue"),
    props: { showLogin: true },
    beforeEnter(to, from, next) {
      handleCategoryNavigation("email-confirmed", to, from, next);
    }
  },
  {
    path: "/profile",
    meta: { requiresAuth: true, breadCrumb: "Area personale" },
    component: () => import("@/views/profile/Profile.vue"),
    children: [
      {
        path: "",
        name: "Dashboard",
        meta: { requiresAuth: true, breadCrumb: "Dashboard" },
        component: () => import("@/views/profile/Dashboard.vue"),
        beforeEnter(to, from, next) {
          handleCategoryNavigation("profile", to, from, next);
        }
      },
      // hide vantaggi
      {
        path: "gifts",
        name: "ProfileGift",
        meta: { requiresAuth: true, breadCrumb: "Vantaggi" },
        component: () => import("@/views/profile/ProfileGift.vue"),
        beforeEnter(to, from, next) {
          handleCategoryNavigation("gifts", to, from, next);
        }
      },
      {
        path: "profile-update",
        name: "ProfileUpdate",
        meta: { requiresAuth: true, breadCrumb: "Il mio profilo" },
        component: () => import("@/views/profile/ProfileUpdate.vue"),
        beforeEnter(to, from, next) {
          handleCategoryNavigation("profile", to, from, next);
        }
      },
      {
        path: "profile-update-error",
        name: "ProfileUpdateError",
        meta: { requiresAuth: true, breadCrumb: "Il mio profilo" },
        component: () => import("@/views/profile/ProfileUpdateError.vue"),
        beforeEnter(to, from, next) {
          handleCategoryNavigation("profile-error", to, from, next);
        }
      },
      {
        path: "update-password",
        name: "UpdatePassword",
        meta: { requiresAuth: true, breadCrumb: "Aggiorna password" },
        component: () => import("@/views/profile/UpdatePassword.vue"),
        beforeEnter(to, from, next) {
          handleCategoryNavigation("update-password", to, from, next);
        }
      },
      {
        path: "profile-confirmed",
        name: "ProfileConfirmed",
        component: () => import("@/views/profile/ProfileConfirmed.vue"),
        beforeEnter(to, from, next) {
          handleCategoryNavigation("profile-confirmed", to, from, next);
        }
      },
      {
        path: "profile-updated",
        name: "ProfileUpdated",
        component: () => import("@/views/EbsnCategory.vue"),
        beforeEnter(to, from, next) {
          handleCategoryNavigation("profile-updated", to, from, next);
        }
      },
      {
        path: "email-updated",
        name: "EmailUpdated",
        component: () => import("@/views/EbsnCategory.vue"),
        beforeEnter(to, from, next) {
          handleCategoryNavigation("email-updated", to, from, next);
        }
      },

      {
        path: "invite",
        name: "Invite",
        meta: { requiresAuth: true, breadCrumb: "Invita i tuoi amici" },
        component: () => import("@/views/profile/Invite.vue"),
        beforeEnter(to, from, next) {
          handleCategoryNavigation("invite", to, from, next);
        }
      },
      {
        path: "news-list",
        name: "NewsListPage",
        meta: { requiresAuth: true, breadCrumb: "Archivio Messaggi" },
        component: () => import("@/views/profile/NewsList.vue"),
        beforeEnter(to, from, next) {
          handleCategoryNavigation("news-list", to, from, next);
        }
      },
      {
        path: "orders",
        meta: {
          requiresAuth: true,
          breadCrumb: "I miei ordini"
        },
        component: {
          render(c) {
            return c("router-view");
          }
        },
        beforeEnter(to, from, next) {
          global.EventBus.$emit("resetFilters");
          next();
        },
        children: [
          {
            name: "Orders",
            path: "",
            meta: {
              requiresAuth: true
            },
            component: () => import("@/views/profile/Orders.vue"),
            beforeEnter(to, from, next) {
              handleCategoryNavigation("orders", to, from, next);
            }
          },
          {
            name: "Order",
            path: ":orderId",
            meta: {
              requiresAuth: true,
              breadCrumb: "Dettaglio ordine"
            },
            component: () => import("@/views/profile/Order.vue"),
            beforeEnter(to, from, next) {
              handleCategoryNavigation("order", to, from, next);
            }
          }
        ]
      },
      {
        path: "lists",
        meta: {
          requiresAuth: true,
          breadCrumb: "Le mie liste"
        },
        component: {
          render(c) {
            return c("router-view");
          }
        },
        beforeEnter(to, from, next) {
          global.EventBus.$emit("resetFilters");
          next();
        },
        children: [
          {
            name: "Lists",
            path: "",
            meta: {
              requiresAuth: true
            },

            component: () => import("@/views/profile/Lists.vue"),
            beforeEnter(to, from, next) {
              handleCategoryNavigation("lists", to, from, next);
            }
          },
          {
            path: ":listId",
            name: "List",
            meta: {
              requiresAuth: true,
              breadCrumb: "Dettaglio lista"
            },
            component: () => import("@/views/profile/List.vue")
          }
        ]
      },
      {
        path: "preferred",
        name: "Favourites",
        meta: {
          requiresAuth: true,
          breadCrumb:
            store && store.getters["cart/hasFavorites"]
              ? "I miei preferiti"
              : "I più acquistati"
        },
        component: () => import("@/views/profile/Favourites.vue")
      },
      {
        path: "addresses",
        meta: { requiresAuth: true, breadCrumb: "I miei indirizzi" },
        // component: () => import("@/views/ProfileAddresses.vue"),
        // beforeEnter(to, from, next) {
        //   handleCategoryNavigation("addresses", to, from, next);
        // },
        component: {
          render(c) {
            return c("router-view");
          }
        },
        children: [
          {
            path: "",
            name: "Addresses",
            meta: {
              requiresAuth: true
            },
            component: () => import("@/views/profile/ProfileAddresses.vue"),
            beforeEnter(to, from, next) {
              handleCategoryNavigation("addresses", to, from, next);
            }
          },
          {
            path: "manage/:addressId?",
            name: "EditAddresses",
            props: { modal: true },
            meta: { requiresAuth: true, breadCrumb: "Dettaglio indirizzo" },
            component: () => import("@/views/profile/EditAddress.vue")
            // components: {
            //   default: require("@/views/ProfileAddresses.vue").default,
            //   modal: require("@/views/EditAddress.vue").default
            // }
          }
        ]
      }
    ]
  }
];

export default routes;
