import { handleCategoryNavigation } from "./routeFunctions";

const routes = [
  {
    path: "/page/:pageName",
    name: "Page",

    component: () => import("@/views/wordpress/Page.vue")
  },
  {
    path: "/faq",
    meta: {
      breadCrumb: "Faq"
    },
    component: () => import("@/views/wordpress/FaqList.vue"),
    async beforeEnter(to, from, next) {
      await handleCategoryNavigation("faq", to, from, next);
    }
  },
  {
    path: "/vantaggi",
    name: "Vantaggi",
    component: () => import("@/views/wordpress/Vantaggi.vue")
  },
  {
    path: "/form/:formId",
    name: "NinjaForms",
    component: () => import("@/commons/components/ninjaForms/NinjaForm.vue"),
    props: true
  }
];

export default routes;
