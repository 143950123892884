import { handleCategoryNavigation } from "./routeFunctions";
import base from "./base";
import wordpress from "./wordpress";
import catalog from "./catalog";
import profile from "./profile";
import checkout from "./checkout";

const routes = [
  {
    path: "/",
    name: "Home",
    props: true,
    component: () => import("@/views/Home.vue"),
    beforeEnter(to, from, next) {
      handleCategoryNavigation("store6-home", to, from, next);
    }
  },

  ...base,
  ...wordpress,
  ...catalog,
  ...profile,
  ...checkout,

  {
    path: "*",
    name: "DefaultRoute",
    component: () => import("@/views/Home.vue"),
    beforeEnter(to, from, next) {
      handleCategoryNavigation("store6-home", to, from, next);
    }
  }
];

export default routes;
