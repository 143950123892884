import store from "@/store";

async function handleCategoryNavigation(slug, to, from, next) {
  let category = await store.dispatch("category/setCategory", {
    slug: slug
    // ,
    // query: to.query.q
  });
  if (category) {
    next();
  } else {
    next("/not-found");
  }
}

export { handleCategoryNavigation };
