<template>
  <v-list flat subheader dense class="py-0">
    <v-list-item>
      <!-- NOTE: check styles below for override for screens with width < 1050 -->
      <div
        class="avatar mr-1 my-1 d-sm-none d-md-inline"
        rounded="0"
        v-if="!isCart"
      >
        <img aria-hidden :src="serviceImg" />
      </div>

      <v-list-item-content>
        <v-list-item-subtitle class="d-flex flex-column align-left">
          <v-row
            no-gutters
            align="center"
            justify="start"
            class="default--text text-body-2"
            :class="{ 'mb-2': isCart }"
          >
            <img
              v-if="isCart"
              class="mini-service"
              aria-hidden
              :src="serviceImg"
            />
            <span
              v-if="isCart"
              class="font-weight-bold ml-2"
              style="line-height:1"
              >{{ $t("cart.cartInfo.editableAddress") }}</span
            >
            <v-btn
              class="cart-info-button text-none font-weight-regular"
              :class="
                (isCart ? 'text-body-2' : 'text-caption',
                isNavbar ? 'font-weight-bold' : 'font-weight-regular')
              "
              :disabled="!editableAddress"
              text
              tile
              small
              elevation="0"
              rounded
              @click="openAddressSelector"
            >
              <span
                class="info-text text-uppercase"
                :class="
                  isCart
                    ? 'text-body-2 line-height-1'
                    : 'text-caption font-weight-bold'
                "
                >{{ shippingAddress }}
              </span>
              <v-icon right v-if="!isCart">$edit</v-icon>
            </v-btn>
            <!-- icon to be shown in cart drawer -->
            <v-icon
              v-if="isCart"
              color="default"
              class="cart-edit-icon"
              @click="openAddressSelector"
              >$edit</v-icon
            >
          </v-row>
          <v-row
            no-gutters
            align="center"
            class="mt-0 default--text text-body-2"
          >
            <v-icon color="primary" v-if="isCart">$editcalendar</v-icon>
            <span
              v-if="isCart"
              class="font-weight-bold ml-2"
              style="line-height:1"
              >{{ $t("cart.cartInfo.timeslot") }}</span
            >
            <v-btn
              class="cart-info-button text-none font-weight-regular"
              :class="isCart ? 'text-body-2' : 'text-caption'"
              :disabled="!editableTimeslot"
              text
              tile
              small
              elevation="0"
              rounded
              @click="openTimeslotSelector"
            >
              <span class="info-text">{{ timeslot }}</span>
              <v-icon right v-if="!isCart">$edit</v-icon></v-btn
            >
            <!-- icon to be shown in cart drawer -->
            <v-icon
              v-if="isCart"
              color="default"
              class="cart-edit-icon"
              @click="openTimeslotSelector"
              >$edit</v-icon
            >
          </v-row>
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>
<style lang="scss" scoped>
.mini-service {
  width: 24px;
  height: 24px;
}
.info-text {
  overflow: hidden;
  text-overflow: ellipsis;
}
.line-height-1 {
  line-height: 1 !important;
}
.avatar {
  img {
    height: 36px;
  }
  @media (max-width: 1049px) {
    display: none !important; // override for small screens
  }
}
.cart-info-button {
  justify-content: left;
  font-size: 12px;
  height: 20px !important;
  letter-spacing: normal;
  .v-btn__content {
    max-width: 225px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .v-icon {
    font-size: $font-size-root;
    height: 14px;
    width: 14px;
  }
}
.cart-edit-icon {
  position: absolute;
  right: 20px;
}
.cart-info-label {
  max-width: 212px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.v-list-item--dense .v-list-item__content,
.v-list--dense .v-list-item .v-list-item__content {
  padding: 1px 0;
}
</style>
<script>
import get from "lodash/get";
import { mapState } from "vuex";
import login from "~/mixins/login";
import cartInfo from "~/mixins/cartInfo";
export default {
  name: "CartInfoList",
  mixins: [login, cartInfo],
  data() {
    return {
      maxWidth: 320,
      maxHeight: 60
    };
  },
  props: {
    isCart: { type: Boolean, required: false },
    isNavbar: { type: Boolean, required: false }
  },
  computed: {
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),
    customAddress() {
      let shippingAddress = get(this.cart, "shippingAddress");
      if (shippingAddress) {
        return `${shippingAddress.addressName} - ${shippingAddress.address1}`;
      }
      return "";
    }
  }
};
</script>
