var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-list',{staticClass:"category-tree grey lighten-3"},[_vm._l((_vm.allCategories),function(category){return _c('v-list-group',{key:category.categoryId,staticClass:"category1",class:{
      'category-custom': !category.skip
    },style:([
      category.metaData && category.metaData.category_info
        ? {
            'border-left':
              '5px solid ' +
              (category.metaData.category_info.MENU_COLOR || 'transparent')
          }
        : null
    ]),attrs:{"append-icon":""},on:{"click":function($event){return _vm.goToCategory(category)}},scopedSlots:_vm._u([{key:"prependIcon",fn:function(){return [(category.metaData && category.metaData.category_info)?_c('img',{attrs:{"src":category.metaData.category_info.ICON}}):_c('span',{staticStyle:{"min-width":"30px"}})]},proxy:true},{key:"activator",fn:function(){return [_c('v-list-item-title',[_c('router-link',{attrs:{"to":{ name: 'Category', params: { pathMatch: category.slug } },"event":""}},[_vm._v(" "+_vm._s(category.name)+" ")])],1)]},proxy:true}],null,true)},[_vm._l((category.categories),function(category2){return [(category2.categories)?_c('v-list-group',{key:category2.categoryId,staticClass:"category2",attrs:{"sub-group":"","prepend-icon":""},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item',{staticClass:"px-0",attrs:{"to":{
              name: 'Category',
              params: { pathMatch: category2.slug }
            }},on:{"click":function($event){return _vm.subToggle(category2.categoryId)}}},[_c('v-list-item-title',{staticClass:"category2-title pl-8"},[_vm._v(" "+_vm._s(category2.name)+" ")])],1)]},proxy:true},(category2.metaData && category2.metaData.category_info)?{key:"prependIcon",fn:function(){return [_c('v-img',{attrs:{"src":category2.metaData.category_info.ICON,"max-width":"24","max-height":"24","contain":"","alt":("Icona categoria\n      " + (category2.name))}})]},proxy:true}:null],null,true),model:{value:(_vm.subActive[category2.categoryId]),callback:function ($$v) {_vm.$set(_vm.subActive, category2.categoryId, $$v)},expression:"subActive[category2.categoryId]"}},_vm._l((category2.categories),function(category3){return _c('v-list-item',{key:category3.categoryId,staticClass:"category3",attrs:{"to":{ name: 'Category', params: { pathMatch: category3.slug } },"color":"grey lighten-3"}},[_c('v-list-item-title',[_vm._v(_vm._s(category3.name))])],1)}),1):_c('v-list-item',{key:category2.categoryId,staticClass:"category2 no-child pl-8",attrs:{"link":"","to":{ name: 'Category', params: { pathMatch: category2.slug } },"color":"grey lighten-3"},on:{"click":function($event){return _vm.subToggle(category.categoryId)}}},[_c('v-list-item-title',[_vm._v(_vm._s(category2.name))])],1)]})],2)}),(_vm.isCordova)?_c('v-list',[_c('div',{staticClass:"bottom"})]):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }