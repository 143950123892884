<template>
  <div>
    <v-list
      id="cartItemList"
      flat
      subheader
      dense
      class="cart-item-list"
      :class="{ 'checkout-item-list': isCheckout }"
    >
      <!-- three-line -->

      <template v-if="!isCheckout">
        <v-subheader
          class="text-caption mx-1 d-flex justify-space-between default--text"
        >
          <CartItemCount :count="cart.totalItems" />
          <v-btn
            color="primary"
            plain
            class="empty-cart-btn d-md-none d-lg-none"
            :disabled="cart.totalItems == 0"
            @click="emptyCartConfirm"
            >{{ $t("cart.emptyCart") }}</v-btn
          >
        </v-subheader>
      </template>

      <div
        :class="[
          { 'cart-item-list-no-checkout': !isCheckout },
          { 'cart-item-list-no-checkout-higher': !isCheckout && checkMobile }
        ]"
      >
        <div
          v-for="cartItem in cart.cartItems"
          :key="cartItem.cartItemId"
          class="align-center px-md-2"
        >
          <CartItem :item="cartItem" :full="full" :isCheckout="isCheckout" />
        </div>
      </div>
    </v-list>
  </div>
</template>
<script>
import CartItem from "./CartItem.vue";
import CartItemCount from "./CartItemCount.vue";

import { mapActions, mapState } from "vuex";
export default {
  name: "CartItemList",
  components: { CartItem, CartItemCount },
  props: {
    full: { type: Boolean, default: false },
    isCheckout: { type: Boolean, default: false },
    checkMobile: { type: Boolean, default: false }
  },
  data() {
    return {
      panel: [],
      showSectors: null
    };
  },
  computed: {
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),
    acceptAlternatives: {
      get() {
        let value = true;
        this.cart.cartItems.forEach(cartItem => {
          if (cartItem.cartItemInfo.accept_alternatives != "true") {
            value = false;
          }
          return value;
        });
        return value;
      },
      set(value) {
        this.setCartItemInfo({
          items: this.cart.cartItems,
          name: "accept_alternatives",
          value: "" + value
        });
      }
    },
    orderComment: {
      get() {
        return this.cart.cartInfos.order_comment;
      },
      set(value) {
        console.log(value);
        //update on blur
      }
    }
  },
  methods: {
    handleRemove() {},
    changeTimeslot() {},
    changeDelivery() {},
    ...mapActions({
      emptyCart: "cart/emptyCart",
      setCartItemInfo: "cart/setCartItemInfo",
      setCartInfo: "cart/setCartInfo"
    }),
    async emptyCartConfirm() {
      if (this.cart.totalItems > 0) {
        let res = await global.vm.$dialog.confirm({
          text: global.vm.$t("message.emptyCart")
        });
        if (res) {
          this.emptyCart();
        }
      }
    }
  },
  mounted() {
    this.showSectors = global.config.showSectors;
    if (global.config.showSectors) {
      if (this.cart && this.cart.sectors) {
        this.panel = this.cart.sectors.map((k, i) => i);
      }
    }
  },
  watch: {
    cart() {
      if (global.config.showSectors) {
        if (this.cart && this.cart.sectors) {
          this.panel = this.cart.sectors.map((k, i) => i);
        }
      }
    }
  }
};
</script>
<style scoped lang="scss">
.v-subheader {
  height: auto;
  min-height: #{$list-subheader-min-height};
  background: linear-gradient(
    #eeeeee 0% #eeeeee 80%,
    rgba(238, 238, 238, 0.7) 100%
  );
}
.cart-item-list-no-checkout {
  min-height: 30vh;
  height: calc(
    100vh - (#{$cart-info-height} + #{$list-subheader-min-height}) - 80px
  );
  //padding-top: #{$list-subheader-min-height};
  //margin-top: -#{$list-subheader-min-height};
  padding-bottom: #{$summary-height};
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: var(--v-grey-darken2);
    border-radius: 20px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}
.cart-item-list-no-checkout-higher {
  height: calc(
    100vh -
      (#{$cart-info-height} + #{$list-subheader-min-height} + #{$cartlist-diff})
  ) !important;
}
@media #{map-get($display-breakpoints, 'xs-only')} {
  .cart-item-list-no-checkout {
    min-height: auto;
    max-height: 100%;
  }
}
</style>
