<template>
  <v-container class="fill-height default--text px-3">
    <v-layout fill-height column mt-5>
      <h3 class="font-weight-bold text-center" v-if="title" v-html="title"></h3>
      <div
        v-if="description"
        v-html="description"
        class="text-center text-body-2 mt-2"
      ></div>
      <!-- v-if="deliveryServices.length > 1" -->
      <v-row
        no-gutters
        justify="space-between"
        align="center"
        class="my-3 tabs"
      >
        <v-col
          :cols="deliveryServiceButtonsLength"
          class="px-2"
          v-for="(service, idx) in deliveryServices"
          :key="service.deliveryServiceId"
          @click="fetchAddresses(service, idx)"
        >
          <div
            class="tab d-flex flex-column align-center justify-center flex-grow-1 pt-6 pb-4 rounded-sm"
            :class="
              service.deliveryServiceId === selectedDeliveryServiceId
                ? `${service.cssClass} selected`
                : `${service.cssClass}`
            "
          >
            <v-icon color="primary">{{ `$${service.iconSource}` }}</v-icon>

            <span
              class="text-body-2 text-uppercase font-weight-bold"
              :class="{
                selected:
                  deliveryService.deliveryServiceId ===
                  service.deliveryServiceId
              }"
              >{{ service.name }}</span
            >
          </div>
        </v-col>
      </v-row>
      <v-card :loading="loading" flat>
        <v-row>
          <!-- <v-col
            cols="12"
            sm="4"
            v-if="deliveryService.deliveryServiceId === 2"
          >
            <v-btn
              color="primary"
              large
              depressed
              block
              :to="{ name: 'Addresses' }"
              @click="$emit('submit', false)"
            >
              AGGIUNGI INDIRIZZO
            </v-btn>
          </v-col> -->
          <!-- :sm="deliveryService.deliveryServiceId === 2 ? 8 : 12" -->
          <v-col :cols="12">
            <v-text-field
              class="rounded-lg"
              hide-details
              v-model="searchText"
              clearable
              filled
              solo
              dense
              :label="
                $t(
                  'navbar.service.' + selectedDeliveryServiceId + '.searchLabel'
                )
              "
            >
              <v-icon slot="append" color="red">
                $search
              </v-icon>
            </v-text-field>
          </v-col>
        </v-row>
        <v-list dense class="address-list">
          <v-list-item-group v-model="shippingAddress" color="primary">
            <v-list-item
              two-line
              v-for="address in filteredAddresses"
              :key="address.addressId"
              @click="setAddress(address)"
              class="px-0 px-sm-auto"
            >
              <v-list-item-content>
                <v-row no-gutters justify="space-between" align="center">
                  <v-icon class="mr-2 ml-2 ml-sm-2">
                    {{ address.selected ? "$radioOn" : "$radioOff" }}
                  </v-icon>
                  <div class="desc">
                    <v-list-item-title
                      class="font-weight-bold text-caption text-sm-body-2 mb-1"
                    >
                      {{ address.addressName }}
                    </v-list-item-title>
                    <v-list-item-subtitle class=" text-caption text-sm-body-2">
                      {{
                        $t(
                          "navbar.address." + address.addressTypeId + ".list",
                          address
                        )
                      }}
                    </v-list-item-subtitle>
                  </div>
                  <v-spacer />
                  <StoreLogo
                    v-for="addressClass in address.addressClass"
                    :addressClass="addressClass"
                    :key="addressClass['address-class-id']"
                  />
                </v-row>
              </v-list-item-content>
              <v-list-item-action class="flex-row align-center ml-0">
                <!-- <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      v-if="
                        address.addressId == cart.user.defaultStoreAddressId ||
                          address.addressId ==
                            cart.user.defaultDeliveryAddressId
                      "
                      >$ratingEmpty</v-icon
                    >
                  </template>
                  <span>{{
                    address.addressType == "home"
                      ? "Indirizzo preferito"
                      : "Negozio preferito"
                  }}</span>
                </v-tooltip> -->
                <v-btn
                  icon
                  :small="$vuetify.breakpoint.xs"
                  @click.stop="edit(address)"
                  v-if="address.addressType == 'home'"
                >
                  <v-icon>$edit</v-icon>
                </v-btn>
                <v-btn
                  icon
                  :small="$vuetify.breakpoint.xs"
                  @click.stop="remove(address)"
                  v-if="address.addressType == 'home'"
                >
                  <v-icon color="promo">$delete</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list-item-group>
        </v-list>
        <v-card-actions class="align-center">
          <v-btn
            v-if="
              deliveryService.deliveryServiceId === 2 &&
                filteredAddresses.length == 0
            "
            color="primary"
            block
            :to="{ name: 'Page', params: { pageName: 'i-servizi-dupliclick' } }"
            @click="$emit('submit', false)"
            align="center"
          >
            i nostri servizi
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-layout>
  </v-container>
</template>
<style scoped lang="scss">
h3 ::v-deep p,
.desc ::v-deep p {
  margin-bottom: 0 !important;
}
.tabs {
  .tab {
    border: 1px solid $gray-border-color;
    &.selected {
      color: $white !important;
      background: $primary;
      .v-icon,
      span {
        color: $white !important;
      }
    }
  }
  .v-icon {
    font-size: 60px;
    height: 80px;
  }
}
.address-list {
  .v-list-item__title,
  .v-list-item__subtitle {
    line-height: 18px !important;
  }
  .v-list-item--active {
    background: $primary;
    color: $white;
    border-radius: $border-radius-root;
    .v-list-item__subtitle {
      color: $white;
    }
    ::v-deep .icon-edit {
      color: $white;
    }
  }
}
.v-text-field ::v-deep .v-input__slot {
  padding: 0 24px !important;
}
// .desc {
//   @media #{map-get($display-breakpoints, 'xs-only')} {
//     max-width: 85%;
//   }
// }
</style>
<script>
// @ is an alias to /src
import StoreService from "~/service/storeService";
import DeliveryService from "~/service/deliveryService";
import CategoryService from "~/service/categoryService";

import { mapState } from "vuex";
import StoreLogo from "../StoreLogo.vue";
import get from "lodash/get";

export default {
  name: "AddressSelector",
  data() {
    return {
      store: {},
      addresses: [],
      loading: null,
      shippingAddress: null,
      deliveryService: null,
      tab: 1,
      searchText: null,
      category: {}
    };
  },
  components: { StoreLogo },
  computed: {
    title() {
      return get(
        this.category,
        "metaData.category_info.TITLE",
        this.category.name
      );
    },
    description() {
      return get(
        this.category,
        "metaData.category_info.DESCRIPTION",
        this.category.description
      );
    },
    deliveryServiceButtonsLength() {
      return 12 / this.deliveryServices.length;
    },
    filteredAddresses() {
      if (this.searchText) {
        return this.addresses.filter(address => {
          return (
            address.addressName
              .toLowerCase()
              .indexOf(this.searchText.toLowerCase()) > -1 ||
            address.address1
              .toLowerCase()
              .indexOf(this.searchText.toLowerCase()) > -1 ||
            address.city.toLowerCase().indexOf(this.searchText.toLowerCase()) >
              -1
          );
        });
      } else {
        return this.addresses;
      }
    },
    ...mapState({
      cart: ({ cart }) => cart.cart,
      selectedService: ({ cart }) => cart.selectedService
    }),
    selectedDeliveryServiceId() {
      return this.deliveryService ? this.deliveryService.deliveryServiceId : 0;
    },
    deliveryServices() {
      if (this.store && this.store.deliveryServices) {
        return this.store.deliveryServices.filter(button => {
          return button.showTab;
        });
      }
      return [];
    }
  },
  methods: {
    addressClass(address) {
      if (address && address.addressClass && address.addressClass.length) {
        return address.addressClass[0];
      }
    },
    async remove(address) {
      let _this = this;
      const res = await _this.$dialog.confirm({
        text: global.vm.$t("message.removeAddress")
      });
      if (res) {
        let response = await DeliveryService.removeAddress(address.addressId);
        _this.addresses = response.addresses;
      }
    },
    edit(address) {
      this.$router.push({
        name: "EditAddresses",
        params: { addressId: address.addressId }
      });
      this.$emit("submit", false);
    },
    async fetchStore() {
      let _this = this;
      let store = await StoreService.getStoreById(_this.cart.store.storeId);
      _this.store = store;

      _this.store.deliveryServices.find(deliveryService => {
        if (
          deliveryService.deliveryServiceId ==
          _this.cart.shippingAddress.deliveryServiceId
        ) {
          _this.deliveryService = deliveryService;
        }
      });
    },
    async fetchAddresses(deliveryService, idx) {
      let _this = this;
      if (typeof idx !== "undefined") {
        _this.tab = idx;
      }
      _this.loading = true;
      _this.searchText = null;
      let deliveryServiceId =
        deliveryService.deliveryServiceId || deliveryService;
      _this.deliveryService = deliveryService;
      _this.addresses = await StoreService.getAddressesByDeliveryService(
        deliveryServiceId
      );

      _this.addresses.find((address, index) => {
        if (address.addressId == _this.cart.shippingAddress.addressId) {
          _this.shippingAddress = index;
        }
      });
      _this.loading = false;
    },
    async setAddress(address) {
      let _this = this;
      this.loading = true;
      let data = await this.$store.dispatch("cart/setAddress", {
        addressType: address.addressType,
        addressId: address.addressId
      });

      this.loading = false;
      if (data) {
        _this.$emit("submit", true);
        //Do not go home on address change
        // setTimeout(function() {
        //   _this.$router.push({ name: "Home" });
        // }, 200);
      }
    },
    async fetchCategory() {
      try {
        this.category = await CategoryService.getCategoryBySlug(
          "service-selector"
        );
      } catch (e) {
        console.log(e);
      }
    }
  },
  created() {
    this.fetchStore();
    this.fetchAddresses(this.cart.shippingAddress.deliveryServiceId);
    this.fetchCategory();
  }
};
</script>
