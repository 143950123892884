<template functional>
  <div class="promo-container">
    <div class="promo-box d-flex" :class="[props.warehousePromo.view.cssClass]">
      <span
        v-html="props.warehousePromo.view.header"
        class="promo-header"
      ></span>
      <span v-html="props.warehousePromo.view.body" class="promo-body"></span>
    </div>
    <div
      v-if="props.warehousePromo.view.footer"
      v-html="props.warehousePromo.view.footer"
      class="promo-footer"
    ></div>
  </div>
</template>
<style lang="scss">
.promo-container {
  .promo-box {
    background-color: $promo;
    color: $white;
    position: relative;
    // top: -1px;
    // right: 6px;
    border-top-left-radius: $border-radius-root;
    width: $promo-box-width;
    height: $promo-box-height;
    -webkit-clip-path: polygon(100% 0, 100% 75%, 75% 100%, 0 100%, 0 0);
    clip-path: polygon(100% 0, 100% 75%, 75% 100%, 0 100%, 0 0);

    &.follie {
      -webkit-clip-path: none;
      clip-path: none;
      background-color: transparent;
    }
    .promo-body {
      height: fit-content;
      font-weight: bold;
      transform: rotate(45deg);
      text-align: center;
    }

    &.order_detail {
      font-size: 13px;
      align-items: center;
      .promo-body {
        font-weight: bold;
        text-align: center;
        width: 100%;
        transform: rotate(45deg);
      }
    }

    &.promo_percent {
      white-space: nowrap;
      font-size: 13px;
      align-items: center;
      &.follie {
        align-items: flex-start;
      }
      .promo-body {
        font-weight: bold;
        text-align: center;
        width: 100%;
        transform: rotate(45deg);
      }
    }
    &.promo_risparmio_special {
      .promo-body {
        font-size: 13px;
        margin-top: 14px;
        margin-left: -5px;
      }
    }
    &.promo_mxn,
    &.promo_3x2 {
      .promo-body {
        font-size: 22px;
        margin-top: 6px;
        margin-left: 8px;
      }
    }
    &.promo_percent_special {
      font-size: 10px;
      align-items: center;
      .promo-body {
        font-weight: bold;
        transform: rotate(45deg);
        line-height: 10px;
      }
    }
    &.da_non_perdere {
      .promo-body {
        position: absolute;
        left: -5px;
        top: 19px;
      }
    }
    &.sottocosto {
      background-color: #666666;
    }
  }
  .promo-footer {
    padding: 1px;
    font-size: 10px;
    font-weight: bold;
    background-color: #e1c850;
    letter-spacing: -1px;
  }
}
.promo-badge {
  font-size: 10px;
  border-radius: 25px !important;
  background-color: $promo;
  color: $white;
  font-weight: bold;
  width: auto;
  max-width: max-content;
  padding: 2px 4px;
  display: -webkit-box;
  max-height: 22px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-height: 1;
}
.product-card {
  .promo-container {
    .promo-footer {
      margin-left: 1px;
    }
  }
}
</style>
<script>
export default {
  name: "ProductPromo",
  props: {
    warehousePromo: { type: Object, required: true }
  }
};
</script>
