<template>
  <v-container class="timeslot-selector fill-height mt-1 px-5 pb-1">
    <v-layout fill-height column>
      <h3
        class="font-weight-bold  mx-auto mt-5 mb-2 text-center"
        v-html="title"
      ></h3>
      <div
        v-if="description"
        class="text-center mb-2"
        v-html="description"
      ></div>
      <v-row
        no-gutters
        class="text-body-2 w-100"
        align="center"
        justify="space-between"
      >
        <div>
          <div
            class="text-uppercase font-weight-bold"
            style="line-height: 18px;"
          >
            {{ $t(`timeslots.label.${selectedServiceId}`) }}
          </div>
          <div class="address">
            <span class="font-weight-bold">{{
              cart.shippingAddress.addressName
            }}</span>
            <span class="mx-2">|</span>
            {{
              $t(
                "navbar.address." +
                  cart.shippingAddress.addressTypeId +
                  ".list",
                cart.shippingAddress
              )
            }}
            <v-icon
              v-if="editableAddress"
              right
              @click="changeAddress"
              color="black"
              :class="{ 'ml-1': !$vuetify.breakpoint.xs }"
              :style="{ 'line-height: 18px': !$vuetify.breakpoint.xs }"
            >
              $edit
            </v-icon>
          </div>
        </div>
        <StoreLogo
          v-for="addressClass in cart.shippingAddress.addressClass"
          :addressClass="addressClass"
          :key="addressClass['address-class-id']"
        />
      </v-row>
      <p
        class="selector-warning selector-warning-under-general-warning text-center"
        v-if="
          timeslotRestrictions.underLeadTimeItems ||
            timeslotRestrictions.dayLock ||
            timeslotRestrictions.lockCutoff
        "
      >
        {{ $t("timeslots.generalWarning") }}
      </p>

      <v-divider></v-divider>
      <div class="text-body-2 font-weight-bold text-uppercase mb-4">
        {{ $t("timeslots.dayLabel") }}
      </div>
      <v-tabs
        hide-slider
        icons-and-text
        v-model="tab"
        height="auto"
        center-active
        slider-size="1"
        show-arrows
        fixed-tabs
      >
        <v-tabs-slider></v-tabs-slider>
        <v-tab
          v-for="timeslotDay in days"
          :key="timeslotDay.dateIso"
          @click="selectTimeslotDay(timeslotDay)"
        >
          <div
            class="time-div d-flex flex-column align-center justify-space-around"
          >
            <template v-if="$dayjs().isSame(timeslotDay.dateIso, 'day')">
              <span class="day-number font-weight-bold">
                OGGI
              </span>
            </template>
            <template v-else>
              <span class="day-string">
                {{ $dayjs(timeslotDay.dateIso).format("dddd") }}
              </span>
              <span class="day-number font-weight-bold">
                {{ $dayjs(timeslotDay.dateIso).format("D") }}
              </span>
              <span class="month">
                {{ $dayjs(timeslotDay.dateIso).format("MMMM") }}
              </span>
            </template>
          </div>
        </v-tab>
      </v-tabs>
      <v-divider></v-divider>

      <div class="text-body-2 font-weight-bold text-uppercase mb-4">
        {{ $t("timeslots.timeLabel") }}
      </div>

      <v-row
        no-gutters
        align="center"
        justify="space-between"
        justify-sm="start"
        class="range-buttons mb-5 mb-md-3 mb-lg-5"
      >
        <v-col
          cols="4"
          sm="auto"
          v-for="range in timeslotRange"
          :key="range.id"
          class="d-flex justify-center mr-0 mr-sm-2"
        >
          <v-btn
            depressed
            class="rounded-sm"
            :class="
              rangeTab === range.id
                ? 'primary white--text active-border'
                : 'white default--text text--darken-2'
            "
            :min-width="$vuetify.breakpoint.xs ? 'auto' : 120"
            :small="$vuetify.breakpoint.xs"
            data-test="range-btn"
            :value="range.id"
            min-height="38"
            @click="rangeTab = range.id"
          >
            <v-icon
              v-if="range.icon"
              :color="rangeTab === range.id ? 'white' : 'default'"
              >{{ range.icon }}</v-icon
            >
            <span class="range-text">{{ $t(range.text) }}</span>
          </v-btn>
        </v-col>
      </v-row>

      <v-tabs-items v-model="tab">
        <v-tab-item
          class="timeslot-container"
          v-for="timeslotDay in days"
          :key="timeslotDay.dateIso"
        >
          <v-row dense v-if="timeslotDay.timeslots.length > 0" no-gutters>
            <v-col
              cols="4"
              sm="3"
              md="2"
              lg="3"
              v-for="timeslot in timeslotDay.timeslots.filter(
                checkDeliveryDayPeriodId
              )"
              :key="timeslot.timeslotId"
            >
              <v-card class="timeslot-card" flat :disabled="disabled">
                <div
                  v-on:click="selectTimeslot(timeslot)"
                  class="timeslot-button d-flex justify-center"
                  :class="[
                    timeslot.status,
                    { 'under-lead-time': timeslot.underLeadTime },
                    { 'under-lock-day': timeslot.underLockDay },
                    { 'under-lock-cutoff': timeslot.underLockCutoff },
                    { selected: timeslot.selected }
                  ]"
                >
                  <!-- <span class="lock-icons">
                    <v-icon
                      v-if="timeslot.underLeadTime"
                      x-small
                      class="lead-time"
                    >
                      $clock
                    </v-icon>
                    <v-icon
                      v-if="timeslot.underLockDay"
                      x-small
                      class="day-lock"
                      aria-label=""
                    >
                      $clock
                    </v-icon>
                    <v-icon
                      v-if="timeslot.underLockCutoff"
                      x-small
                      class="lock-cutoff"
                    >
                      $clock
                    </v-icon>
                  </span> -->
                  <span class="lock-icons">
                    <v-icon
                      v-if="
                        timeslot.underLeadTime ||
                          timeslot.underLockDay ||
                          timeslot.underLockCutoff
                      "
                      x-small
                    >
                      $clock
                    </v-icon>
                  </span>
                  <span class="timeslot-time"
                    >{{ timeslot.beginTime }} - {{ timeslot.endTime }}</span
                  >
                </div>
              </v-card>
            </v-col>
          </v-row>
          <p v-else>
            {{ $t("timeslots.noSlots") }}
          </p>
        </v-tab-item>
      </v-tabs-items>
      <!-- <v-spacer></v-spacer> -->
      <v-row class="legend mt-10 mt-md-0" no-gutters>
        <v-col cols="6" sm="3" class="py-0">
          <div class="timeslot-legend">
            <div class="marker empty"></div>
            <span class="text-body-2">Disponibile</span>
          </div>
        </v-col>
        <v-col cols="6" sm="3" class="py-0">
          <div class="timeslot-legend">
            <div class="marker intermediate"></div>
            <span class="text-body-2">Quasi esaurita</span>
          </div>
        </v-col>
        <v-col cols="6" sm="3" class="py-0">
          <div class="timeslot-legend">
            <div class="marker full"></div>
            <span class="text-body-2">Non disponibile</span>
          </div>
        </v-col>
        <v-col cols="6" sm="3" class="py-0">
          <div class="timeslot-legend">
            <div class="marker selected"></div>
            <span class="text-body-2">Selezionata</span>
          </div>
        </v-col>
      </v-row>
    </v-layout>
  </v-container>
</template>
<style scoped lang="scss">
// .lock-icons {
//   position: absolute;
//   top: 0;
//   right: 7px;
//   .lead-time {
//     color: $lead-time-color;
//   }
//   .day-lock {
//     color: $day-lock-color;
//   }
//   .lock-cutoff {
//     color: $lock-cutoff-color;
//   }
// }
.timeslot-selector {
  color: var(--v-default-base);
  height: 100%;
  .category-title {
    h1 {
      text-align: center;
      font-weight: 700 !important;
      font-size: 26px;
    }
  }
  .range-buttons {
    .v-btn {
      border: 1px solid $gray-border-color !important;
    }
    .active-border {
      border: 1px solid var(--v-primary-lighten1) !important;
    }
  }

  .selector-warning {
    margin: 0;
    @media #{map-get($display-breakpoints, 'sm-and-down')} {
      font-size: 14px;
    }
    &.selector-warning-under-lead-time {
      color: darken($lead-time-color, 30%);
    }
    &.selector-warning-under-lock-day {
      color: darken($day-lock-color, 30%);
    }
    &.selector-warning-under-lock-cutoff {
      color: darken($lock-cutoff-color, 30%);
    }
  }
  .v-tabs {
    flex-grow: unset;
  }
  .v-slide-group__wrapper {
    background-color: white;
  }
  .v-divider {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .title {
    font-size: 25px;
  }
  .subtitle {
    font-size: 16px;
  }
  .timeslot-container {
    flex-wrap: wrap;
    justify-content: center;
    min-height: 315px;
    @media #{map-get($display-breakpoints, 'md-only')} {
      min-height: auto;
    }
  }
  .legend {
    flex-grow: unset;
    margin-top: 10px !important;
  }
  .timeslot-card {
    overflow: hidden;
    justify-content: center;
  }
  .timeslot-button {
    border-radius: 5px;
    border: 1px solid #e5e5e5;
    margin: 3px;
    font-size: 15px;
    font-weight: normal;
    pointer-events: auto;
    cursor: pointer;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px 7px;
    position: relative;
    color: $text-color;

    .timeslot-time {
      font-size: 18px;
      @media #{map-get($display-breakpoints, 'md-only')} {
        font-size: 15px;
      }
    }
  }
  .timeslot-legend {
    margin: 3px;
    display: flex;
    align-items: center;
    .marker {
      width: 32px;
      height: 32px;
      display: inline-block;
      vertical-align: middle;
      margin-right: 5px;
      border-radius: 4px;
    }
    span {
      vertical-align: middle;
    }
  }

  .empty {
    color: var(--v-default-darken2);
    background-color: #fff;
    border: 1px solid #e9e9e9;
  }
  .intermediate {
    color: var(--v-default-darken2);
    background-color: var(--v-yellow-lighten1);
    border-color: var(--v-yellow-lighten1);
    &.selected {
      border-color: $primary;
    }
  }
  .full {
    color: white;
    background-color: var(--v-error-base);
    border-color: var(--v-error-base);
    &.selected {
      border-color: $primary;
    }
  }
  .selected {
    color: #fff;
    background-color: $primary;
  }
  .v-tab {
    border: 1px solid var(--v-grey-lighten1);
    color: $primary;
    padding: 0px !important;
    margin: 0px 5px 0px 5px !important;
    border-radius: 5px;
    min-width: 100px;
    min-height: 100px;

    text-transform: capitalize;
    @media #{map-get($display-breakpoints, 'sm-and-up')} {
      min-width: 70px;
      min-height: 70px;
      height: 130px;
      width: 130px !important;
    }
    .time-div {
      color: $text-color;
      .day-string {
        margin-bottom: 5px;
        font-size: 16px;
        letter-spacing: 0.1px;
      }
      .day-number {
        font-size: 29px;
        letter-spacing: 0.17px;
      }
      .month {
        margin-top: 5px;
        font-size: 16px;
        letter-spacing: 0.1px;
      }
    }
  }
  .v-tab.v-tab--active {
    border-radius: 5px;
    color: $white;
    background-color: $primary;
    .time-div {
      color: $white;
    }
  }

  .v-slide-group__content {
    height: 100%;
  }

  .address {
    line-height: 18px;
  }
}
@media #{map-get($display-breakpoints, 'xs-only')} {
  .timeslot-selector {
    .timeslot-button {
      padding: 7px 5px;
      .timeslot-time {
        font-size: 15px;
      }
      .timeslot-status {
        font-size: 10px;
      }
    }
  }
  // .address {
  //   max-width: 85%;
  // }
}
</style>
<script>
import StoreLogo from "@/components/StoreLogo.vue";
import DeliveryService from "~/service/deliveryService";
import CategoryService from "~/service/categoryService";
import { forEachCartItem } from "~/service/ebsn";

import { mapState } from "vuex";
import toNumber from "lodash/toNumber";
import isNumber from "lodash/isNumber";
import split from "lodash/split";
import get from "lodash/get";

export default {
  name: "TimeslotSelector",
  data() {
    return {
      days: {},
      tab: 0,
      category: {},
      selectedTimeslotDay: {},
      disabled: false,
      rangeTab: 0,
      timeslotRange: {
        0: { id: 0, text: "timeslots.allTimeRanges" },
        1: { id: 3, icon: "$day", text: "timeslots.morning" },
        2: { id: 2, icon: "$night", text: "timeslots.afternoon" }
      }
    };
  },
  components: {
    StoreLogo
  },
  computed: {
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),
    editableAddress() {
      return !(this.$route.name == "Payment" || this.cart.cartStatusId == 7);
    },
    selectedServiceId() {
      return this.cart.shippingAddress.deliveryServiceId;
    },
    title() {
      return get(
        this.category,
        "metaData.category_info.TITLE",
        this.category.name
      );
    },
    description() {
      return get(
        this.category,
        "metaData.category_info.DESCRIPTION",
        this.category.description
      );
    },
    timeslotRestrictions() {
      var result = {};
      let maxLeadTime = 0;
      forEachCartItem(this.cart, item => {
        let leadTime = toNumber(item.product.productInfos.LEAD_TIME);
        if (isNumber(leadTime) && leadTime > maxLeadTime) {
          maxLeadTime = leadTime;
        }
        result.underLeadTimeItems = maxLeadTime > 0;

        if (item.product.productInfos.DAY_LOCK) {
          if (!result.dayLock) result.dayLock = [];
          let array = split(item.product.productInfos.DAY_LOCK, " ");
          if (item.product.productInfos.DAY_LOCK.indexOf(",") > -1) {
            array = split(item.product.productInfos.DAY_LOCK, ",");
          }
          for (let i = 0; i < array.length; i++) {
            if (array[i].indexOf("_") > -1) {
              // "day_deliveryDayPeriodId, es: lun_2"
              let daySplitted = array[i].split("_");
              result.dayLock.push({
                day: daySplitted[0],
                deliveryDayPeriodId: daySplitted[1]
              });
            } else {
              // "day, es: lun"
              result.dayLock.push({
                day: array[i],
                deliveryDayPeriodId: undefined
              });
            }
          }
        }

        if (item.product.productInfos.PRODUCT_LOCK_CUTOFF) {
          if (!result.lockCutoff) result.lockCutoff = [];
          let arrayLock = split(
            item.product.productInfos.PRODUCT_LOCK_CUTOFF,
            " "
          );
          if (item.product.productInfos.PRODUCT_LOCK_CUTOFF.indexOf(",") > -1) {
            arrayLock = split(
              item.product.productInfos.PRODUCT_LOCK_CUTOFF,
              ","
            );
          }
          // arrayLock[0] = "16:00-18:00";
          for (var i = 0; i < arrayLock.length; i++) {
            let hours = arrayLock[i].split("-");
            result.lockCutoff.push(hours);
          }
        }
      });
      result.leadTimeLimit = this.$dayjs().add(maxLeadTime, "hour");
      // result.dayLock = null;
      return result;
    }
  },
  methods: {
    changeAddress() {
      this.$emit("submit", "setAddress");
    },
    async openAddressSelector() {
      if (await this.setAddress()) {
        await this.needTimeslot();
      }
    },
    checkIfIsToday(date) {
      return this.$dayjs().isSame(date, "day");
    },
    checkDeliveryDayPeriodId(item) {
      if (this.rangeTab == 0) {
        return item;
      }
      if (this.rangeTab == 2) {
        return !item.deliveryDayPeriodId;
      }
      return (
        item.deliveryDayPeriodId && item.deliveryDayPeriodId <= this.rangeTab
      );
    },
    selectTimeslotDay(day) {
      this.selectedTimeslotDay = day;
    },
    async selectTimeslot(timeslot) {
      let _this = this;
      if (timeslot.active < 1) {
        return;
      }
      _this.disabled = true;
      let data = await _this.$store.dispatch("cart/setTimeslot", {
        dateIso: _this.selectedTimeslotDay.dateIso,
        timeslotId: timeslot.timeslotId
      });
      if (data) {
        this.$emit("submit", true);
      }
    },
    async fetchTimeslots() {
      let _this = this;
      _this.days = await DeliveryService.getTimeslotList(true);

      if (_this.days.length > 1 && _this.days[0].timeslots.length == 0) {
        _this.days.shift();
      }

      for (var i = 0; i < _this.days.length; i++) {
        if (_this.days[i].dateIso == _this.cart.timeslot.date) {
          _this.tab = i;
          this.selectedTimeslotDay = _this.days[i];
          break;
        }
      }
      if (!_this.selectedTimeslotDay.dateIso) {
        _this.selectTimeslotDay(_this.days[0]);
      }
      return false;
    },
    async fetchCategory() {
      try {
        this.category = await CategoryService.getCategoryBySlug(
          "timeslot-selector"
        );
      } catch (e) {
        console.log(e);
      }
    }
  },
  mounted() {
    this.disabled = false;
    this.fetchTimeslots();
    this.fetchCategory();
  }
};
</script>
