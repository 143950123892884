import Vue from "vue";
import FingerprintJS from "@fingerprintjs/fingerprintjs";

// Initialize an agent at application startup.
const fpPromise = FingerprintJS.load({ monitoring: false });

Object.defineProperties(Vue.prototype, {
  $fingerprint: {
    async get() {
      const fp = await fpPromise;
      const result = await fp.get();
      return result;
    }
  }
});
