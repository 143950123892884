<template>
  <v-menu bottom offset-y left v-if="isSsoUser">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        fab
        v-if="isSsoUser"
        fixed
        top
        right
        color="warning"
        height="45"
        width="45"
        class="sso-btn"
        v-bind="attrs"
        v-on="on"
      >
        <v-icon>$eye</v-icon>
      </v-btn>
    </template>
    <v-list>
      <v-list-item>
        Sei loggato come
      </v-list-item>
      <v-list-item class="pb-5">
        <strong>{{ isSsoUser.username }}</strong>
      </v-list-item>
      <v-divider class="divider"></v-divider>
      <v-list-item @click.stop="logout()" class="py-2">
        <v-list-item-title v-text="'Esci'"></v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "SsoUserdCard",
  computed: {
    ...mapGetters({
      isSsoUser: "cart/isSsoUser"
    })
  },
  methods: {
    ...mapActions({
      doLogout: "cart/doLogout"
    }),
    async logout() {
      console.log("TO-DO: Logout sso user");
    }
  }
};
</script>
<style scoped lang="scss">
.v-list {
  padding-bottom: 0px !important;
}
.v-list-item {
  min-height: 5px;
  padding-left: 10px;
  padding-right: 10px;
}
.divider {
  margin-left: 10px;
  margin-right: 10px;
}
</style>
