<template>
  <img
    v-if="addressClass && addressClass.iconSource"
    class="store-logo"
    :src="addressClass.iconSource"
    height="40"
    contain
    :alt="`logo ${addressClass.name}`"
  />
</template>
<style lang="scss" scoped>
.store-logo {
  height: 40px;
  max-width: 150px;
}
</style>
<script>
export default {
  name: "StoreLogo",
  props: { addressClass: { type: Object, required: true } }
};
</script>
