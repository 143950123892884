<template functional>
  <div class="price">
    <!-- <div v-if="props.product.warehousePromo" class="price-block mxn">
        <div class="promoMxN-price" v-if="props.product.priceStandardDisplay">
          {{ parent.$n(props.product.priceStandardDisplay, "currency") }}
          <span class="other">1 pz</span>
        </div>
        <div
          class="cur-price"
          :class="{ 'promo-price': props.product.priceStandardDisplay }"
          itemprop="price"
        >
          {{
            parent.$n(
              props.product.warehousePromo.view.promoOther.nPrice,
              "currency"
            )
          }}
          <span class="other">
            {{ props.product.warehousePromo.view.promoOther.m }} pz
          </span>
        </div>
      </div> -->
    <div
      class="price_block"
      :class="
        props.product.warehousePromo
          ? props.product.warehousePromo.view.cssClass
          : ''
      "
    >
      <div class="old-price my-1" v-if="props.product.priceStandardDisplay">
        <span>
          Anzichè
          {{ parent.$n(props.product.priceStandardDisplay, "currency") }}</span
        >
        &nbsp;
      </div>
      <div
        v-if="props.product.priceDisplay"
        class="cur-price"
        :class="{ 'promo--text': props.product.priceStandardDisplay }"
      >
        {{ parent.$n(props.product.priceDisplay, "currency") }}
        <span
          class="weight-unit"
          v-if="props.product.productInfos.TIPOLOGIA != 'Pezzo'"
          >/{{ props.product.weightUnitDisplay }}
        </span>
      </div>
      <div class="cur-price" v-else>-</div>
      <div
        class="price-um"
        v-if="
          props.product.productInfos.TIPOLOGIA == 'Pezzo' &&
            props.product.priceUmDisplay
        "
      >
        <span>
          ({{ parent.$n(props.product.priceUmDisplay, "currency") }} /{{
            props.product.weightUnitDisplay
          }})
        </span>
        &nbsp;
      </div>
    </div>
  </div>
</template>
<style scoped lang="scss">
.price {
  display: flex;
  flex-direction: column;
  font-size: 24px;

  line-height: 1;
  color: $text-color;
  white-space: nowrap;
  .weight-unit {
    font-size: 12px;
    margin-left: -5px;
  }
  .old-price {
    font-size: 11px;
  }
  .cur-price {
    font-weight: bold;
  }
  .other {
    white-space: nowrap;
    font-size: 12px;
    text-decoration: none !important;
  }
  .original {
    font-size: 16px;
  }
  .price-um {
    font-size: 10px;
    line-height: 15px;
    //padding: 2px 0 12px 0;
  }
  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    max-height: 63px;
    align-items: center;
  }
}
</style>
<script>
export default {
  name: "props.productPrice",
  props: { product: { type: Object, required: true } }
};
</script>
