import Vue from "vue";
import Vuetify from "vuetify/lib";
import i18n from "./i18n";
import "@/scss/_fonts.scss";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    //disable: true,
    light: true,
    options: {
      customProperties: true
    },
    themes: {
      light: {
        logo: {
          default: "/logo/duplicarclick.svg",
          mobile: "/logo/duplicarclick-mobile.svg"
        },
        default: {
          base: "#454545",
          darken1: "#4A4A4A",
          darken2: "#3C3C3C"
        },
        primary: {
          base: "#00889D",
          lighten1: "#29B8CE",
          lighten2: "#F0F7Fc",
          lighten3: "#eef8fc",
          darken1: "#034D58"
        },
        secondary: {
          base: "#ABCA56",
          lighten1: "#DFFD86",
          darken1: "#799925"
        },
        white: "#FFFFFF",
        black: "#000000",
        grey: {
          base: "#BBBBBB",
          lighten1: "#DDDDDD",
          lighten2: "#EEEEEE",
          lighten3: "#FCFCFC",
          lighten4: "#D9D9D9",
          darken1: "#777777",
          darken2: "#888888",
          darken3: "#454545"
        },
        yellow: {
          base: "#EFD256",
          lighten1: "#FFF89C"
        },
        promo: "#C62A2A",
        news: "#FF3E8B",
        error: "#FF5252",
        warning: "#FB8C00",
        success: "#4CAF50"
      }
    }
  },
  lang: {
    t: (key, ...params) => i18n.t(key, params)
  },
  icons: {
    values: {
      // cardArrow: "icon-card-arrow",
      // clock: "icon-clock"
      // delete: "icon-delete",
      // edit: "icon-edit",
      arrowBack: "icon-arrow-back",
      arrowDownward: "icon-arrow-downward",
      arrowForward: "icon-arrow-forward",
      arrowTopLeft: "icon-arrow-top-left",
      arrowUpward: "icon-arrow-upward",
      cancel: "icon-close",
      cart: "icon-cart",
      checkboxIndeterminate: "...",
      checkboxOff: "icon-checkbox-off",
      checkboxOn: "icon-checkbox-on",
      clear: "icon-close",
      close: "icon-close",
      complete: "icon-complete",
      creditcard: "icon-creditcard",
      day: "icon-day",
      delete: "icon-close", // delete (e.g. v-chip close)
      delimiter: "icon-delimiter", // for carousel
      delivery: "icon-delivery",
      dots: "icon-dots",
      dropdown: "icon-dropdown",
      edit: "icon-edit",
      editcalendar: "icon-edit-calendar",
      error: "icon-warning",
      euro: "icon-euro",
      expand: "icon-expand",
      expandLess: "icon-expand-less",
      eye: "icon-eye-full",
      eyeOff: "icon-eye-off-full",
      file: "...",
      filter: "icon-filter",
      first: "...",
      geolocate: "icon-geolocate",
      heart: "icon-heart",
      heartfull: "icon-heartfull",
      home: "icon-home",
      info: "icon-info",
      last: "...",
      like: "icon-like",
      loading: "...",
      location: "icon-location",
      locationDashboard: "icon-location-dashboard",
      locker: "icon-locker",
      logout: "icon-logout",
      menu: "icon-menu",
      microphone: "icon-microphone",
      minus: "icon-remove",
      mood: "icon-mood",
      next: "icon-next",
      night: "icon-night",
      noPackage: "icon-no-package",
      orders: "icon-orders",
      package: "icon-package",
      percent: "icon-percent",
      pickup: "icon-pickup",
      plus: "icon-add",
      power: "icon-power",
      prev: "icon-prev",
      print: "icon-print",
      products: "icon-products",
      profile: "icon-profile",
      profileDashboard: "icon-profile-dashboard",
      qrCode: "icon-qr-code",
      radioOff: "icon-radio-unchecked",
      radioOn: "icon-radio-checked",
      ratingEmpty: "icon-star-outline",
      ratingFull: "icon-star",
      ratingHalf: "icon-star-half",
      receipt: "icon-receipt",
      search: "icon-search",
      sort: "icon-sort",
      storefront: "icon-storefront",
      subgroup: "...",
      success: "...",
      tag: "icon-tag",
      unfold: "...",
      warning: "icon-warning"
    }
  }
});
