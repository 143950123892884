<template>
  <v-list class="category-tree grey lighten-3">
    <v-list-group
      v-for="category in allCategories"
      :key="category.categoryId"
      class="category1"
      :class="{
        'category-custom': !category.skip
      }"
      v-bind:style="[
        category.metaData && category.metaData.category_info
          ? {
              'border-left':
                '5px solid ' +
                (category.metaData.category_info.MENU_COLOR || 'transparent')
            }
          : null
      ]"
      append-icon=""
      @click="goToCategory(category)"
    >
      <template v-slot:prependIcon>
        <img
          v-if="category.metaData && category.metaData.category_info"
          :src="category.metaData.category_info.ICON"
        />
        <span style="min-width: 30px" v-else></span>
      </template>
      <template v-slot:activator>
        <v-list-item-title>
          <router-link
            :to="{ name: 'Category', params: { pathMatch: category.slug } }"
            event
          >
            {{ category.name }}
          </router-link>
        </v-list-item-title>
      </template>
      <template v-for="category2 in category.categories">
        <v-list-group
          v-model="subActive[category2.categoryId]"
          sub-group
          class="category2"
          v-if="category2.categories"
          :key="category2.categoryId"
          prepend-icon=""
        >
          <template v-slot:activator>
            <v-list-item
              @click="subToggle(category2.categoryId)"
              class="px-0"
              :to="{
                name: 'Category',
                params: { pathMatch: category2.slug }
              }"
            >
              <v-list-item-title class="category2-title pl-8">
                {{ category2.name }}
              </v-list-item-title>
            </v-list-item>
          </template>
          <template
            v-slot:prependIcon
            v-if="category2.metaData && category2.metaData.category_info"
          >
            <v-img
              :src="category2.metaData.category_info.ICON"
              max-width="24"
              max-height="24"
              contain
              :alt="
                `Icona categoria
        ${category2.name}`
              "
            />
            <!-- <span style="min-width: 30px" v-else></span> -->
          </template>

          <v-list-item
            v-for="category3 in category2.categories"
            :key="category3.categoryId"
            :to="{ name: 'Category', params: { pathMatch: category3.slug } }"
            color="grey lighten-3"
            class="category3"
          >
            <v-list-item-title>{{ category3.name }}</v-list-item-title>
          </v-list-item>
        </v-list-group>
        <v-list-item
          v-else
          @click="subToggle(category.categoryId)"
          link
          :to="{ name: 'Category', params: { pathMatch: category2.slug } }"
          color="grey lighten-3"
          class="category2 no-child pl-8"
          :key="category2.categoryId"
        >
          <v-list-item-title>{{ category2.name }}</v-list-item-title>
        </v-list-item>
      </template>
    </v-list-group>
    <v-list v-if="isCordova"><div class="bottom"></div></v-list>
  </v-list>
</template>
<script>
import deliveryReactive from "~/mixins/deliveryReactive";
import { mapState, mapActions, mapGetters } from "vuex";
import categoryService from "@/commons/service/categoryService";
import each from "lodash/each";
export default {
  name: "CategoryTree",
  mixins: [deliveryReactive],
  data() {
    return {
      expandedKeys: [],
      selectedKey: null,
      threeCategories: [],
      subActive: {}
    };
  },
  computed: {
    ...mapState({
      categories: ({ category }) => category.categoryTree
    }),
    ...mapGetters({
      isAuthenticated: "cart/isAuthenticated"
    }),
    allCategories() {
      if (
        this.threeCategories &&
        this.threeCategories.children &&
        this.categories
      ) {
        each(this.categories, category => {
          category.skip = true;
        });
        return this.isAuthenticated
          ? this.categories?.concat(this.threeCategories.children)
          : this.threeCategories.children?.concat(this.categories);
      } else return this.categories;
    }
  },
  methods: {
    subToggle(categoryId) {
      for (let i = 0; i < Object.keys(this.subActive).length; i++) {
        if (categoryId != Object.keys(this.subActive)[i]) {
          this.subActive[Object.keys(this.subActive)[i]] = false;
        }
      }
    },
    ...mapActions({
      reload: "category/loadCategoryTree"
    }),
    goToCategory(category) {
      if (!category.skip) {
        this.$router.push({
          name: "Category",
          params: { pathMatch: category.slug }
        });
      }
    },
    async getCategories() {
      try {
        this.threeCategories = await categoryService.getCategoryBySlug(
          "category-tree",
          false
        );
      } catch (e) {
        console.log(e);
      }
    }
  },
  created() {
    this.reload();
    this.getCategories();
  }
};
</script>
<style lang="scss">
.category-tree {
  .category-custom {
    font-weight: bold;
    text-transform: none !important;
  }

  .category2 {
    &.v-list-group--sub-group .v-list-group__header {
      padding-left: 0px !important;
    }
  }
}
</style>
<style scoped lang="scss">
.theme--light.v-list-item--active:hover::before,
.theme--light.v-list-item--active::before {
  opacity: 0;
}
.category-tree {
  a {
    color: inherit !important;
    text-decoration: inherit !important;
  }
  .v-list-item__icon {
    height: 48px !important;
    width: 48px !important;
    .v-image__image--cover {
      background-size: initial !important;
    }
  }
  .category1 {
    border-bottom: 1px solid var(--v-grey-lighten1);
    .v-list-item {
      max-height: 50px !important;
      .no-icon {
        min-width: 42px;
        display: inline-block;
      }
    }
    .v-list-item__title {
      font-size: 13px;
      color: $text-color;
    }
    &.v-list-group:last-child {
      border-bottom: 1px solid transparent;
    }
    &.v-list-group--active {
      .v-list-item__title > a {
        font-weight: 700;
      }
    }
    @media #{map-get($display-breakpoints, 'xs-only')} {
      ::v-deep.v-list-item__icon {
        margin: auto 8px auto 0 !important;
      }
      .v-list-group__header__prepend-icon {
        img {
          max-width: 18px;
        }
      }
    }
  }
  .category2 {
    padding-left: 26px;
    // fix for category2 as direct link, not group header
    // .v-list-item .v-list-item__title {
    //   padding-left: 32px;
    // }
    // .v-list-group__header.v-list-item,
    &.v-list-group--sub-group .v-list-group__header {
      padding-left: 0px !important;
    }
    &.v-list-group--active {
      .category2-title {
        color: $primary !important;
        font-weight: 700;
      }
    }
    &.no-child {
      padding-left: 32px;
      &.v-list-item--active {
        .v-list-item__title {
          color: $primary !important;
          font-weight: 700;
        }
      }

      margin-left: 24px;
    }
    // .v-list-group--active
    &.v-list-group--sub-group {
      .v-list-item__title > a {
        font-weight: 400;
      }
    }

    .v-list-item--active {
      .v-list-item__title a {
        color: $primary !important;
        font-weight: 700;
      }
    }
  }
  .category3 {
    padding-left: 48px !important;
    &.v-list-item--active {
      .v-list-item__title {
        color: $text-color !important;
        font-weight: 700 !important;
      }
    }
  }
  .v-list--dense {
    .v-list-item {
      .v-list-item__icon {
        margin: 16px 8px !important;
      }
    }
  }
  .bottom {
    height: 100px;
  }
  @media #{map-get($display-breakpoints, 'xs-only')} {
    // fix for mobile browser, because otherwise with browser's navigation bar opened last category of the tree is not visible
    padding-bottom: 100px;
  }
}
</style>
