<template>
  <div class="cart-item">
    <v-list-item
      :to="{
        name: 'Product',
        params: { slug: item.product.slug }
      }"
      :class="{ 'py-3': !isCheckout }"
    >
      <v-row class="w-100" align="center" justify="space-between">
        <v-col cols="12" sm="6" class="pa-0">
          <v-row no-gutters>
            <v-list-item-avatar
              :width="full ? '80' : '60'"
              :height="full ? '80' : '60'"
              rounded="0"
            >
              <img
                :src="item.product.mediaURL"
                :alt="item.product.name"
                onerror="this.onerror=null;this.src='/no-icon.png'"
              />
            </v-list-item-avatar>

            <v-list-item-content class="default--text pa-0 pa-sm-2">
              <div class="font-weight-bold text-body-2">
                {{ item.product.name }}
              </div>
              <v-list-item-subtitle class="text-uppercase text-body-2">
                {{ item.product.shortDescr }}
              </v-list-item-subtitle>
              <v-list-item-subtitle
                v-if="item.cartItemInfo.customWeight"
                class="cart-item-info"
              >
                <em>Grammatura: {{ item.cartItemInfo.customWeight }}gr</em>
              </v-list-item-subtitle>

              <v-list-item-subtitle
                v-if="
                  itemAdjustment &&
                    itemAdjustment.userGiftCertificateId &&
                    itemAdjustment.giftCertificate
                "
                class="cart-item-info promo--text"
              >
                <em>{{ itemAdjustment.giftCertificate.name }}: </em>
                <!-- -{{ $n(itemAdjustment.valueVariation, "currency") }} -->
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-row>
        </v-col>
        <v-col cols="12" sm="6" class="pa-0">
          <v-list-item-action class="mr-0 w-100">
            <div
              class="d-flex align-center w-100"
              :class="
                isCheckout || $vuetify.breakpoint.xs
                  ? 'justify-space-between'
                  : 'justify-end'
              "
            >
              <ProductPrice
                class="product-price"
                :product="item.product"
                :showStandard="false"
                v-if="full"
                :isCheckout="isCheckout"
              ></ProductPrice>
              <div class="promo-wrapper">
                <ProductPromo
                  v-if="item.product.warehousePromo && item.discount > 0"
                  :warehousePromo="item.product.warehousePromo"
                />
              </div>
              <div
                v-if="
                  !(
                    item.product.productInfos &&
                    item.product.productInfos.USER_DISABLED == '1'
                  )
                "
                class="qty-wrap rounded-pill mx-1"
                :class="{ 'not-empty': quantity > 0, 'w-100': isCheckout }"
              >
                <a
                  role="button"
                  class="minus rounded-circle"
                  @click.prevent.stop="minus"
                  @mousedown.stop
                  v-ripple
                >
                  <v-icon>$minus</v-icon>
                </a>
                <div class="val-cnt">
                  <span class="val">{{ quantity }} {{ unit }}</span>
                  <span class="small">{{ quantityPerUnit }}</span>
                </div>
                <a
                  role="button"
                  class="plus rounded-circle"
                  @click.prevent.stop="plus"
                  @mousedown.stop
                  v-ripple
                >
                  <v-icon v-if="quantity > 0">$plus</v-icon>
                  <v-icon v-else>$cart</v-icon>
                </a>
              </div>
              <div class="price text-body-2 font-weight-bold">
                <!-- <span v-if="item.discount > 0" class="promo--text">
                  -{{ $n(item.discount, "currency") }} <br />
                </span> -->
                {{ $n(item.grossTotal, "currency") }}
              </div>
              <v-btn
                icon
                aria-label="Rimuovi dal carrello"
                @click.prevent="remove"
                @mousedown.stop
                color="promo"
                v-if="
                  !(
                    item.product.productInfos &&
                    item.product.productInfos.USER_DISABLED == '1'
                  )
                "
              >
                <v-icon>$close</v-icon>
              </v-btn>
            </div>
          </v-list-item-action>
        </v-col>
      </v-row>
    </v-list-item>
    <v-divider></v-divider>
    <v-dialog v-model="removeDialog" max-width="400">
      <!-- <div class="vuedl-layout__closeBtn" @click="removeDialog = false">
        ×
      </div> -->
      <v-card>
        <v-card-text class="body-1 pt-8">
          {{
            $t("message.remove", {
              name: this.product.name,
              quantity: this.quantity,
              unit: this.unit
            })
          }}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn outlined text @click="removeDialog = false">
            No
          </v-btn>

          <v-btn
            class="primary white--text"
            text
            @click.prevent="doRemove"
            @mousedown.stop
          >
            Si
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<style scoped>
.gross-total {
  width: auto !important;
}
</style>
<script>
import productMixin from "~/mixins/product";
import ProductPrice from "@/components/product/ProductPrice";
import ProductPromo from "@/components/product/ProductPromo";
import { mapGetters } from "vuex";

export default {
  name: "CartItem",
  components: {
    ProductPrice,
    ProductPromo
  },
  props: {
    full: { type: Boolean, default: false },
    isCheckout: { type: Boolean, default: false },
    item: { type: Object, required: true }
  },
  mixins: [productMixin],
  data() {
    return { removeDialog: false };
  },
  computed: {
    product() {
      return this.item.product;
    },
    classCardContainerObject: function() {
      if (this.isCheckout && this.$vuetify.breakpoint.mdAndUp) {
        return "isCheckout d-flex flex-column justify-space-around";
      } else {
        if (!this.$vuetify.breakpoint.xs) {
          return "d-flex flex-row justify-space-around";
        }
        return "XS";
      }
    },
    itemAdjustment() {
      return this.getGiftOrderAdjustmentByItemId(this.item.itemId);
    },
    ...mapGetters({
      getGiftOrderAdjustmentByItemId: "cart/getGiftOrderAdjustmentByItemId"
    })
  },
  methods: {
    remove() {
      this.removeDialog = true;
    },
    doRemove() {
      this.removeDialog = false;
      this.removeItem(this.item);
    }
  }
};
</script>
<style scoped lang="scss">
.cart-item {
  .product-price {
    min-width: 90px;
    text-align: right;
  }
  .promo-box {
    margin-right: 2px;
  }
}
.gross-total {
  min-width: 70px;
  text-align: right;
}
@media #{map-get($display-breakpoints, 'xs-only')} {
  .cart-item {
    // .price {
    //   width: 25%;
    // }
    .gross-total {
      min-width: auto !important;
      width: 25%;
      font-size: 18px !important;
    }
  }
}
</style>
