<template>
  <div class="login fill-height mt-1">
    <v-container fluid fill-height>
      <v-layout>
        <v-flex xs12 mt-5>
          <v-form
            ref="form"
            v-model="valid"
            @submit.prevent.stop="handleSubmit"
          >
            <v-card class="elevation-0 px-0 px-sm-7 pb-5">
              <v-img
                v-if="!hideLogo"
                eager
                src="/logo/duplicarclick.svg"
                height="57"
                width="200"
                class="mx-auto"
                :alt="'logo DupliClick'"
              />
              <div class="text-h2 default--text text-center mt-8 mb-4">
                {{ $t("login.title") }}
              </div>

              <div
                class="text-body-2 default--text text-center"
                v-html="$t('login.subtitle')"
              />

              <v-spacer></v-spacer>
              <v-card-text>
                <v-text-field
                  v-model="email"
                  :rules="emailRules"
                  :error-messages="errors"
                  type="email"
                  label="Indirizzo email"
                  autocomplete="off"
                  class="mb-2"
                  :id="setUsernameId"
                  required
                  clearable
                  filled
                  solo
                  dense
                ></v-text-field>
                <v-text-field
                  v-model="password"
                  label="Password"
                  autocomplete="off"
                  :append-icon="showPassword ? '$eye' : '$eyeOff'"
                  :type="showPassword ? 'text' : 'password'"
                  :rules="passwordRules"
                  :id="setPasswordId"
                  @click:append="toggleShowPassword"
                  required
                  clearable
                  filled
                  solo
                  dense
                ></v-text-field>

                <div
                  class="d-flex flex-column flex-sm-row align-left align-sm-center justify-space-between remember-password"
                >
                  <v-checkbox
                    color="primary"
                    class="ma-0 mb-7 mb-sm-0"
                    hide-details
                    v-model="rememberMe"
                  >
                    <template v-slot:label>
                      <div class="text-caption">
                        {{ $t("login.rememberOnDevice") }}
                      </div>
                    </template>
                  </v-checkbox>
                  <div
                    class="default--text font-weight-bold"
                    v-if="enableRegistration || !hideRegistration"
                  >
                    <a
                      @click="goToResetPassword"
                      class="text-decoration-underline text-caption"
                      :class="$vuetify.breakpoint.xs ? 'default--text' : ''"
                    >
                      {{ $t("login.rememberPassword") }}
                    </a>
                  </div>
                  <div v-else-if="!isCordova">
                    <a
                      href="/Recupera-Password"
                      class="text-decoration-underline text-caption"
                      :class="$vuetify.breakpoint.xs ? 'default--text' : ''"
                      >{{ $t("login.rememberPassword") }}</a
                    >
                  </div>
                </div>

                <ResponseMessage :response="response" class="mt-4" />
                <v-row no-gutters>
                  <v-col cols="12">
                    <v-btn
                      :loading="loading"
                      type="submit"
                      class="primary my-8 white--text"
                      large
                      block
                      depressed
                    >
                      {{ $t("login.buttonLogin") }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-divider v-if="!hideRegistration" />
              <v-card-actions v-if="!hideRegistration">
                <div
                  class="d-flex flex-column w-100 align-center mt-2"
                  v-if="enableRegistration"
                >
                  <div class="text-h5 font-weight-bold mb-5">
                    {{ $t("login.messageRegister") }}
                  </div>
                  <v-btn
                    @click="handleRegister"
                    class="primary--text"
                    large
                    outlined
                    block
                    depressed
                  >
                    {{ $t("login.buttonRegister") }}
                  </v-btn>
                </div>
                <div
                  class="d-flex flex-column w-100 align-center mt-2"
                  v-else-if="!isCordova"
                >
                  <div class="text-h5 font-weight-bold mb-5">
                    {{ $t("login.messageRegister") }}
                  </div>
                  <v-btn
                    href="https://www.gruppopoli.it/it/registrazione/"
                    class="primary--text"
                    large
                    outlined
                    block
                    depressed
                    >{{ $t("login.buttonRegister") }}
                  </v-btn>
                </div>
              </v-card-actions>
            </v-card>
          </v-form>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<style scoped lang="scss">
.default--text {
  a {
    color: var(--v-default-base) !important;
    font-weight: bold !important;
  }
}
</style>

<script>
import ResponseMessage from "@/components/ResponseMessage";
// import CheckDisclaimerDialog from "@/components/profile/CheckDisclaimerDialog.vue";

import pushNotification from "~/mixins/pushNotification";

// import PoliCustomService from "@/service/poliCustomService";

import { mapActions, mapGetters } from "vuex";

import {
  requiredValue,
  isEmail,
  isPasswordMinimumLength
} from "~/validator/validationRules";

export default {
  name: "Login",
  props: {
    hideLogo: { type: Boolean, required: false, default: false },
    hideRegistration: { type: Boolean, required: false, default: false }
  },
  mixins: [pushNotification],
  components: { ResponseMessage },
  data() {
    return {
      loading: null,
      showPassword: false,
      email: "",
      password: "",
      rememberMe: true,
      response: {},
      valid: true,
      lazy: true,
      emailRules: [requiredValue("Digitare l'e-mail"), isEmail()],
      passwordRules: [
        requiredValue("Digitare la password"),
        isPasswordMinimumLength()
      ],
      errors: [],
      invalid: null
    };
  },
  computed: {
    enableRegistration() {
      return global.config.registration;
    },
    setUsernameId() {
      let usernameId = "username";
      if (this.isCordova) {
        let random = Math.random();
        usernameId = usernameId + "-" + random;
      }
      return usernameId;
    },
    setPasswordId() {
      let passwordId = "password";
      if (this.isCordova) {
        let random = Math.random();
        passwordId = passwordId + "-" + random;
      }
      return passwordId;
    }
  },
  methods: {
    ...mapGetters({
      isAuthenticated: "cart/isAuthenticated"
    }),
    ...mapActions({
      doLogin: "cart/doLogin",
      loadCart: "cart/loadCart"
    }),
    goToResetPassword() {
      this.$router.push({
        name: "ResetPasswordRequest"
      });
      this.$emit("submit", false);
    },
    toggleShowPassword(e) {
      e.preventDefault();
      this.showPassword = !this.showPassword;
    },
    async handleSubmit() {
      let _this = this;
      let token = null;
      if (this.$recaptchaLoaded) {
        await this.$recaptchaLoaded();
        token = await this.$recaptcha("login");
      }
      if (_this.password.length > 0) {
        try {
          _this.loading = true;
          _this.response = {};
          await _this.doLogin({
            email: _this.email,
            password: _this.password,
            rememberMe: _this.rememberMe,
            token: token
          });
          if (this.isAuthenticated) {
            global.EventBus.$emit("login");
          }
          this.$emit("submit", true);
        } catch (response) {
          this.response = response;
        } finally {
          this.loading = false;
        }
      }
    },
    handleRegister() {
      this.$router.push({
        name: "RegistrationUser",
        path: "/registration-user"
      });
      this.$emit("submit", false);
    }
  }
};
</script>
