var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"cart-item"},[_c('v-list-item',{class:{ 'py-3': !_vm.isCheckout },attrs:{"to":{
      name: 'Product',
      params: { slug: _vm.item.product.slug }
    }}},[_c('v-row',{staticClass:"w-100",attrs:{"align":"center","justify":"space-between"}},[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12","sm":"6"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-list-item-avatar',{attrs:{"width":_vm.full ? '80' : '60',"height":_vm.full ? '80' : '60',"rounded":"0"}},[_c('img',{attrs:{"src":_vm.item.product.mediaURL,"alt":_vm.item.product.name,"onerror":"this.onerror=null;this.src='/no-icon.png'"}})]),_c('v-list-item-content',{staticClass:"default--text pa-0 pa-sm-2"},[_c('div',{staticClass:"font-weight-bold text-body-2"},[_vm._v(" "+_vm._s(_vm.item.product.name)+" ")]),_c('v-list-item-subtitle',{staticClass:"text-uppercase text-body-2"},[_vm._v(" "+_vm._s(_vm.item.product.shortDescr)+" ")]),(_vm.item.cartItemInfo.customWeight)?_c('v-list-item-subtitle',{staticClass:"cart-item-info"},[_c('em',[_vm._v("Grammatura: "+_vm._s(_vm.item.cartItemInfo.customWeight)+"gr")])]):_vm._e(),(
                _vm.itemAdjustment &&
                  _vm.itemAdjustment.userGiftCertificateId &&
                  _vm.itemAdjustment.giftCertificate
              )?_c('v-list-item-subtitle',{staticClass:"cart-item-info promo--text"},[_c('em',[_vm._v(_vm._s(_vm.itemAdjustment.giftCertificate.name)+": ")])]):_vm._e()],1)],1)],1),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12","sm":"6"}},[_c('v-list-item-action',{staticClass:"mr-0 w-100"},[_c('div',{staticClass:"d-flex align-center w-100",class:_vm.isCheckout || _vm.$vuetify.breakpoint.xs
                ? 'justify-space-between'
                : 'justify-end'},[(_vm.full)?_c('ProductPrice',{staticClass:"product-price",attrs:{"product":_vm.item.product,"showStandard":false,"isCheckout":_vm.isCheckout}}):_vm._e(),_c('div',{staticClass:"promo-wrapper"},[(_vm.item.product.warehousePromo && _vm.item.discount > 0)?_c('ProductPromo',{attrs:{"warehousePromo":_vm.item.product.warehousePromo}}):_vm._e()],1),(
                !(
                  _vm.item.product.productInfos &&
                  _vm.item.product.productInfos.USER_DISABLED == '1'
                )
              )?_c('div',{staticClass:"qty-wrap rounded-pill mx-1",class:{ 'not-empty': _vm.quantity > 0, 'w-100': _vm.isCheckout }},[_c('a',{directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"minus rounded-circle",attrs:{"role":"button"},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.minus.apply(null, arguments)},"mousedown":function($event){$event.stopPropagation();}}},[_c('v-icon',[_vm._v("$minus")])],1),_c('div',{staticClass:"val-cnt"},[_c('span',{staticClass:"val"},[_vm._v(_vm._s(_vm.quantity)+" "+_vm._s(_vm.unit))]),_c('span',{staticClass:"small"},[_vm._v(_vm._s(_vm.quantityPerUnit))])]),_c('a',{directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"plus rounded-circle",attrs:{"role":"button"},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.plus.apply(null, arguments)},"mousedown":function($event){$event.stopPropagation();}}},[(_vm.quantity > 0)?_c('v-icon',[_vm._v("$plus")]):_c('v-icon',[_vm._v("$cart")])],1)]):_vm._e(),_c('div',{staticClass:"price text-body-2 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$n(_vm.item.grossTotal, "currency"))+" ")]),(
                !(
                  _vm.item.product.productInfos &&
                  _vm.item.product.productInfos.USER_DISABLED == '1'
                )
              )?_c('v-btn',{attrs:{"icon":"","aria-label":"Rimuovi dal carrello","color":"promo"},on:{"click":function($event){$event.preventDefault();return _vm.remove.apply(null, arguments)},"mousedown":function($event){$event.stopPropagation();}}},[_c('v-icon',[_vm._v("$close")])],1):_vm._e()],1)])],1)],1)],1),_c('v-divider'),_c('v-dialog',{attrs:{"max-width":"400"},model:{value:(_vm.removeDialog),callback:function ($$v) {_vm.removeDialog=$$v},expression:"removeDialog"}},[_c('v-card',[_c('v-card-text',{staticClass:"body-1 pt-8"},[_vm._v(" "+_vm._s(_vm.$t("message.remove", { name: this.product.name, quantity: this.quantity, unit: this.unit }))+" ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"outlined":"","text":""},on:{"click":function($event){_vm.removeDialog = false}}},[_vm._v(" No ")]),_c('v-btn',{staticClass:"primary white--text",attrs:{"text":""},on:{"click":function($event){$event.preventDefault();return _vm.doRemove.apply(null, arguments)},"mousedown":function($event){$event.stopPropagation();}}},[_vm._v(" Si ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }