<template>
  <v-container class="pa-0 white">
    <div class="d-flex justify-space-between align-center my-5 pl-4">
      <div class="text-body-2 font-weight-bold default--text text-uppercase">
        {{ $t("links.title") }}
      </div>
      <!-- <v-btn
        large
        icon
        plain
        @click="$emit('close')"
        color="default"
        class="no-border"
      >
        <v-icon>$close</v-icon>
      </v-btn> -->
    </div>
    <div>
      <div
        no-gutters
        v-for="(link, index) in links"
        v-bind:key="index"
        :class="
          isCordovaPlatform(link.classes) ? 'd-none' : 'section default--text'
        "
      >
        <a
          :href="link.url"
          @click="onLinkClick"
          target="_blank"
          class="text-body-2 text-uppercase default--text text-decoration-none pl-4"
          >{{ link.title }}</a
        >
        <p
          v-for="(child, childIdx) in link.children"
          :key="childIdx"
          class="my-1 child pl-4"
        >
          <!-- "socials" column not rendered on mobile app -->
          <a
            :href="child.url"
            @click="onLinkClick"
            :target="child.target"
            class="text-body-2 default--text text-decoration-none"
          >
            <v-img
              v-if="child.classes == 'appstore-bg'"
              src="/img_layout/footer/app-store.png"
              contain
              position="initial"
              :max-height="$vuetify.breakpoint.sm ? 35 : 45"
              alt="logo App Store"
            />
            <v-img
              v-else-if="child.classes == 'gplay-bg'"
              src="/img_layout/footer/google-play.png"
              contain
              position="initial"
              :max-height="$vuetify.breakpoint.sm ? 35 : 45"
              alt="logo Google Play"
            />
            <span v-else>{{ child.title }}</span></a
          >
        </p>
      </div>
    </div>
    <div class="section default--text last" v-if="isCordova">
      <i18n path="footer.version" tag="span" class="my-1 child pl-4">
        {{ version }}
      </i18n>
    </div>
  </v-container>
</template>
<style scoped lang="scss">
.section {
  border-bottom: 1px solid #e9e9e9;
  padding: 12px 0;
  &:first-of-type {
    padding-top: 0 !important;
  }
}
.section.last {
  border-bottom: none;
}
</style>
<script>
import clickHandler from "~/mixins/clickHandler";
import page from "~/mixins/page";
export default {
  mixins: [clickHandler, page],
  props: ["links"],
  computed: {
    version() {
      return global.config.version;
    }
  },
  methods: {
    isSocialLinksColumn(classes) {
      return classes.search("social") > -1;
    },
    isCordovaPlatform(classes) {
      return this.isSocialLinksColumn(classes) && this.cordovaPlatform;
    },
    onLinkClick(e) {
      this.$emit("close");
      this.$nextTick(() => {
        this.clicked(e);
      });
    }
  }
};
</script>
