<template>
  <v-fab-transition>
    <v-btn
      v-scroll="onScroll"
      v-show="fab"
      fab
      fixed
      center
      bottom
      color="primary lighten-1"
      @click="toTop"
      class="scroll-top"
    >
      <v-icon>$arrowUpward</v-icon>
      <span class="description">Torna all'inizio</span>
    </v-btn>
  </v-fab-transition>
</template>
<style lang="scss">
.scroll-top {
  // bottom: 84px;
  // .description {
  //   display: none;
  // }
  // bottom: 90px;
  right: 50%;
  transform: translate(50%, 0);
  height: initial !important;
  width: initial !important;
  border-radius: 25px !important;
  padding: 4px 8px;
  .description {
    display: inline;
  }
}
.is-cordova {
  .scroll-top {
    bottom: 90px;
    right: 50%;
    transform: translate(50%, 0);
    height: initial !important;
    width: initial !important;
    border-radius: 25px !important;
    padding: 4px 8px;
    .description {
      display: inline;
    }
  }
}
.is-cordova.keyboard-open {
  .scroll-top {
    bottom: 20px;
  }
}
.is-cordova.keyboard-open.platform-ios {
  .scroll-top {
    bottom: 35px;
  }
}
.is-cordova.platform-ios {
  .scroll-top {
    bottom: 135px;
  }
}
</style>
<script>
export default {
  data() {
    return {
      fab: false
    };
  },
  methods: {
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 20;
    },
    toTop() {
      this.$vuetify.goTo(0);
    }
  }
};
</script>
