var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"timeslot-selector fill-height mt-1 px-5 pb-1"},[_c('v-layout',{attrs:{"fill-height":"","column":""}},[_c('h3',{staticClass:"font-weight-bold  mx-auto mt-5 mb-2 text-center",domProps:{"innerHTML":_vm._s(_vm.title)}}),(_vm.description)?_c('div',{staticClass:"text-center mb-2",domProps:{"innerHTML":_vm._s(_vm.description)}}):_vm._e(),_c('v-row',{staticClass:"text-body-2 w-100",attrs:{"no-gutters":"","align":"center","justify":"space-between"}},[_c('div',[_c('div',{staticClass:"text-uppercase font-weight-bold",staticStyle:{"line-height":"18px"}},[_vm._v(" "+_vm._s(_vm.$t(("timeslots.label." + _vm.selectedServiceId)))+" ")]),_c('div',{staticClass:"address"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.cart.shippingAddress.addressName))]),_c('span',{staticClass:"mx-2"},[_vm._v("|")]),_vm._v(" "+_vm._s(_vm.$t( "navbar.address." + _vm.cart.shippingAddress.addressTypeId + ".list", _vm.cart.shippingAddress ))+" "),(_vm.editableAddress)?_c('v-icon',{class:{ 'ml-1': !_vm.$vuetify.breakpoint.xs },style:({ 'line-height: 18px': !_vm.$vuetify.breakpoint.xs }),attrs:{"right":"","color":"black"},on:{"click":_vm.changeAddress}},[_vm._v(" $edit ")]):_vm._e()],1)]),_vm._l((_vm.cart.shippingAddress.addressClass),function(addressClass){return _c('StoreLogo',{key:addressClass['address-class-id'],attrs:{"addressClass":addressClass}})})],2),(
        _vm.timeslotRestrictions.underLeadTimeItems ||
          _vm.timeslotRestrictions.dayLock ||
          _vm.timeslotRestrictions.lockCutoff
      )?_c('p',{staticClass:"selector-warning selector-warning-under-general-warning text-center"},[_vm._v(" "+_vm._s(_vm.$t("timeslots.generalWarning"))+" ")]):_vm._e(),_c('v-divider'),_c('div',{staticClass:"text-body-2 font-weight-bold text-uppercase mb-4"},[_vm._v(" "+_vm._s(_vm.$t("timeslots.dayLabel"))+" ")]),_c('v-tabs',{attrs:{"hide-slider":"","icons-and-text":"","height":"auto","center-active":"","slider-size":"1","show-arrows":"","fixed-tabs":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tabs-slider'),_vm._l((_vm.days),function(timeslotDay){return _c('v-tab',{key:timeslotDay.dateIso,on:{"click":function($event){return _vm.selectTimeslotDay(timeslotDay)}}},[_c('div',{staticClass:"time-div d-flex flex-column align-center justify-space-around"},[(_vm.$dayjs().isSame(timeslotDay.dateIso, 'day'))?[_c('span',{staticClass:"day-number font-weight-bold"},[_vm._v(" OGGI ")])]:[_c('span',{staticClass:"day-string"},[_vm._v(" "+_vm._s(_vm.$dayjs(timeslotDay.dateIso).format("dddd"))+" ")]),_c('span',{staticClass:"day-number font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$dayjs(timeslotDay.dateIso).format("D"))+" ")]),_c('span',{staticClass:"month"},[_vm._v(" "+_vm._s(_vm.$dayjs(timeslotDay.dateIso).format("MMMM"))+" ")])]],2)])})],2),_c('v-divider'),_c('div',{staticClass:"text-body-2 font-weight-bold text-uppercase mb-4"},[_vm._v(" "+_vm._s(_vm.$t("timeslots.timeLabel"))+" ")]),_c('v-row',{staticClass:"range-buttons mb-5 mb-md-3 mb-lg-5",attrs:{"no-gutters":"","align":"center","justify":"space-between","justify-sm":"start"}},_vm._l((_vm.timeslotRange),function(range){return _c('v-col',{key:range.id,staticClass:"d-flex justify-center mr-0 mr-sm-2",attrs:{"cols":"4","sm":"auto"}},[_c('v-btn',{staticClass:"rounded-sm",class:_vm.rangeTab === range.id
              ? 'primary white--text active-border'
              : 'white default--text text--darken-2',attrs:{"depressed":"","min-width":_vm.$vuetify.breakpoint.xs ? 'auto' : 120,"small":_vm.$vuetify.breakpoint.xs,"data-test":"range-btn","value":range.id,"min-height":"38"},on:{"click":function($event){_vm.rangeTab = range.id}}},[(range.icon)?_c('v-icon',{attrs:{"color":_vm.rangeTab === range.id ? 'white' : 'default'}},[_vm._v(_vm._s(range.icon))]):_vm._e(),_c('span',{staticClass:"range-text"},[_vm._v(_vm._s(_vm.$t(range.text)))])],1)],1)}),1),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.days),function(timeslotDay){return _c('v-tab-item',{key:timeslotDay.dateIso,staticClass:"timeslot-container"},[(timeslotDay.timeslots.length > 0)?_c('v-row',{attrs:{"dense":"","no-gutters":""}},_vm._l((timeslotDay.timeslots.filter(
              _vm.checkDeliveryDayPeriodId
            )),function(timeslot){return _c('v-col',{key:timeslot.timeslotId,attrs:{"cols":"4","sm":"3","md":"2","lg":"3"}},[_c('v-card',{staticClass:"timeslot-card",attrs:{"flat":"","disabled":_vm.disabled}},[_c('div',{staticClass:"timeslot-button d-flex justify-center",class:[
                  timeslot.status,
                  { 'under-lead-time': timeslot.underLeadTime },
                  { 'under-lock-day': timeslot.underLockDay },
                  { 'under-lock-cutoff': timeslot.underLockCutoff },
                  { selected: timeslot.selected }
                ],on:{"click":function($event){return _vm.selectTimeslot(timeslot)}}},[_c('span',{staticClass:"lock-icons"},[(
                      timeslot.underLeadTime ||
                        timeslot.underLockDay ||
                        timeslot.underLockCutoff
                    )?_c('v-icon',{attrs:{"x-small":""}},[_vm._v(" $clock ")]):_vm._e()],1),_c('span',{staticClass:"timeslot-time"},[_vm._v(_vm._s(timeslot.beginTime)+" - "+_vm._s(timeslot.endTime))])])])],1)}),1):_c('p',[_vm._v(" "+_vm._s(_vm.$t("timeslots.noSlots"))+" ")])],1)}),1),_c('v-row',{staticClass:"legend mt-10 mt-md-0",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"6","sm":"3"}},[_c('div',{staticClass:"timeslot-legend"},[_c('div',{staticClass:"marker empty"}),_c('span',{staticClass:"text-body-2"},[_vm._v("Disponibile")])])]),_c('v-col',{staticClass:"py-0",attrs:{"cols":"6","sm":"3"}},[_c('div',{staticClass:"timeslot-legend"},[_c('div',{staticClass:"marker intermediate"}),_c('span',{staticClass:"text-body-2"},[_vm._v("Quasi esaurita")])])]),_c('v-col',{staticClass:"py-0",attrs:{"cols":"6","sm":"3"}},[_c('div',{staticClass:"timeslot-legend"},[_c('div',{staticClass:"marker full"}),_c('span',{staticClass:"text-body-2"},[_vm._v("Non disponibile")])])]),_c('v-col',{staticClass:"py-0",attrs:{"cols":"6","sm":"3"}},[_c('div',{staticClass:"timeslot-legend"},[_c('div',{staticClass:"marker selected"}),_c('span',{staticClass:"text-body-2"},[_vm._v("Selezionata")])])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }